<template>
  <div class="">
    <div class="columns is-centered is-vcentered my-8 mx-6">
      <div
        class="column is-2 has-text-centered text-white bg-gray-100 is-centered rounded-lg box"
        style="align-self: flex-start"
      >
        <button
          class="button is-large bg-yellow-200 text-dark is-block margin-auto is-fullwidth"
        >
          <span class="is-size-7 is-small-left-heading">CLOSED</span>
          {{
            vueNumberFormat((getClosedTickets * 100) / 100, { precision: 0 })
          }}</button
        ><br />
        <button
          class="button is-large bg-sky-600 text-white is-block margin-auto is-fullwidth"
        >
          <span class="is-size-7 is-small-left-heading">WORKING</span>
          {{
            vueNumberFormat((getInProgressTickets * 100) / 100, {
              precision: 0,
            })
          }}</button
        ><br />
        <button
          class="button is-large bg-emerald-600 text-white is-block margin-auto is-fullwidth"
        >
          <span class="is-size-7 is-small-left-heading">OPENED</span>
          {{
            vueNumberFormat((getOpenedTickets * 100) / 100, { precision: 0 })
          }}</button
        ><br />
        <button
          class="button is-large bg-black text-white is-block margin-auto is-fullwidth"
        >
          <span class="is-size-7 is-small-left-heading">INVOICED</span>
          {{
            vueNumberFormat((getInvoicedTickets * 100) / 100, { precision: 0 })
          }}
        </button>
      </div>
      <div class="column is-8">
        <div class="is-flex is-justify-content-space-between">
          <h1 class="is-size-3">Stats</h1>
          <div class="select">
            <select v-model="selected_database">
              <option value="">All</option>
              <option
                v-for="(camp, index) in databases_data"
                :key="index"
                :value="camp.name"
              >
                {{ camp.friendly_name }}
              </option>
            </select>
          </div>
        </div>
        <table
          class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
        >
          <thead>
            <tr>
              <td colspan="2"></td>
              <td
                v-for="database in databases"
                class="has-text-centered"
                :key="database.id"
              >
                <div class="box has-text-white bg-nav p-1">
                  {{ database.friendly_name.toUpperCase() }}
                </div>
              </td>
            </tr>
          </thead>
          <tbody class="has-text-info">
            <tr>
              <td rowspan="4" width="150" class="group-report">TICKETS</td>
              <td class="has-text-dark" width="150">Open</td>
              <td
                v-for="database in databases"
                :key="database.id"
                class="has-text-centered"
              >
                {{
                  vueNumberFormat(
                    (reports.find((e) => e.database_id == database.name)
                      ?.opened_tickets *
                      100) /
                      100,
                    { precision: 0 }
                  )
                }}
              </td>
            </tr>
            <tr>
              <td class="has-text-dark">In Progress</td>
              <td
                v-for="database in databases"
                :key="database.id"
                class="has-text-centered"
              >
                {{
                  vueNumberFormat(
                    (reports.find((e) => e.database_id == database.name)
                      ?.inprogress_tickets *
                      100) /
                      100,
                    { precision: 0 }
                  )
                }}
              </td>
            </tr>
            <tr>
              <td class="has-text-dark">Invoices</td>
              <td
                v-for="database in databases"
                :key="database.id"
                class="has-text-centered"
              >
                {{
                  vueNumberFormat(
                    (reports.find((e) => e.database_id == database.name)
                      ?.invoiced_tickets *
                      100) /
                      100,
                    { precision: 0 }
                  )
                }}
              </td>
            </tr>
            <tr>
              <td class="has-text-dark">Closed</td>
              <td
                v-for="database in databases"
                :key="database.id"
                class="has-text-centered"
              >
                {{
                  vueNumberFormat(
                    (reports.find((e) => e.database_id == database.name)
                      ?.closed_tickets *
                      100) /
                      100,
                    { precision: 0 }
                  )
                }}
              </td>
            </tr>
            <tr>
              <td rowspan="2" class="group-report">MAINTENANCES</td>
              <td class="has-text-dark">Pending</td>
              <td
                v-for="database in databases"
                :key="database.id"
                class="has-text-centered"
              >
                {{ get_random_number(0, 0) }}
              </td>
            </tr>
            <tr>
              <td class="has-text-dark">Upcoming</td>
              <td
                v-for="database in databases"
                :key="database.id"
                class="has-text-centered"
              >
                {{ get_random_number(0, 0) }}
              </td>
            </tr>
            <tr>
              <td rowspan="2" class="group-report">SWIPE</td>
              <td class="has-text-dark">Pickups</td>
              <td
                v-for="database in databases"
                :key="database.id"
                class="has-text-centered"
              >
                {{ get_random_number(0, 0) }}
              </td>
            </tr>
            <tr>
              <td class="has-text-dark">Request</td>
              <td
                v-for="database in databases"
                :key="database.id"
                class="has-text-centered"
              >
                {{ get_random_number(0, 0) }}
              </td>
            </tr>
          </tbody>
        </table>
        <h1 class="is-size-3">Schedule</h1>
        <Calendar
          class="max-w-full"
          :masks="masks"
          :attributes="attributes"
          is-expanded
        >
          <template v-slot:day-content="{ day, attributes }">
            <div class="flex flex-col h-full z-10 overflow-hidden text-center">
              <span class="day-label text-sm text-white bg-gray-500">{{
                day.day
              }}</span>
              <div
                class="flex-grow overflow-y-auto overflow-x-auto text-center"
              >
                <p
                  v-for="attr in attributes"
                  :key="attr.key"
                  class="p-1 mt-0 mb-1"
                  :class="attr.customData.class"
                >
                  {{ attr.customData.title }}
                </p>
              </div>
            </div>
          </template>
        </Calendar>
      </div>
      <div class="column is-2 mt-2" style="align-self: flex-start">
        <div class="clearfix bg-gray-100 p-2 mb-1 border border-gray-300">
          <h1 class="is-small is-pulled-right">
            <i class="fa-solid fa-star-of-life"></i>
            NEW
          </h1>
        </div>
        <a href="" class="is-block has-text-link border-b-2 pl-2">
          <i class="fa fa-plus mr-2"></i> Schedule Task
        </a>
        <a href="" class="is-block has-text-link border-b-2 pl-2">
          <i class="fa fa-plus mr-2"></i> Maintenance
        </a>
        <a href="" class="is-block has-text-link border-b-2 pl-2">
          <i class="fa fa-plus mr-2"></i> Contract
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { Calendar } from "v-calendar";
import "v-calendar/dist/style.css";
import Api from "@/services/Api";
export default {
  components: {
    Calendar,
  },
  data() {
    return {
      databases_data: [],
      colors: ["blue", "red", "gray", "sky", "emerald", "orange", "cyan"],
      reports: [],
      masks: {
        weekdays: "WWW",
      },
      /*attributes: [
				{
					key: 1,
					customData: {
						title: 'Maintenance @ Pabrade',
						class: 'has-text-dark bg-red-300',
					},
					dates: new Date(2022, month, 1),
				},
				{
					key: 2,
					customData: {
						title: 'Maintenance @ Niamey',
						class: 'has-text-dark bg-blue-300',
					},
					dates: new Date(2022, month, 26),
				},
			],*/

      selected_database: "",
    };
  },
  computed: {
    databases() {
      return this.databases_data.filter((d) => {
        if (this.selected_database == "") return true;
        else return d.name == this.selected_database;
      });
    },
    attributes() {
      const month = new Date().getMonth();
      var attrs = [];
      for (let index = 0; index < this.get_random_number(100, 300); index++) {
        attrs.push({
          key: index,
          customData: {
            title: "Maintenance @ " + this.get_camp(this.selected_database),
            class:
              "has-text-dark bg-" +
              this.colors[Math.floor(Math.random() * this.colors.length)] +
              "-50",
          },
          dates: new Date(2022, month, this.get_random_number(1, 29)),
        });
      }
      return attrs;
    },
    getClosedTickets() {
      let sum = 0;
      let a = this.reports;
      debugger; // eslint-disable-line no-debugger

      Object.keys(a).forEach((key) => {
        sum += parseInt(a[key]["closed_tickets"]);
      });
      return sum;
    },
    getOpenedTickets() {
      let sum = 0;
      let a = this.reports;

      Object.keys(a).forEach((key) => {
        sum += parseInt(a[key]["opened_tickets"]);
      });
      return sum;
    },
    getInProgressTickets() {
      let sum = 0;
      let a = this.reports;

      Object.keys(a).forEach((key) => {
        sum += parseInt(a[key]["inprogress_tickets"]);
      });
      return sum;
    },
    getInvoicedTickets() {
      let sum = 0;
      let a = this.reports;

      Object.keys(a).forEach((key) => {
        sum += parseInt(a[key]["invoiced_tickets"]);
      });
      return sum;
    },
  },

  methods: {
    get_random_number(min = 5, max = 100) {
      // console.log(min, max)
      // return 0
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    get_camp(c) {
      var r = "";
      if (c == "") {
        r =
          this.databases_data.length > 0
            ? this.databases_data[0].friendly_name
            : "";
      } else {
        this.databases_data.map((d) => {
          if (d.name == c) r = d.friendly_name;
        });
      }
      return r;
    },
    getFacilityReport(idx) {
      Api(true)
        .post("ticket/report/" + idx, {})
        .then((response) => {
          this.reports = [...this.reports, ...response.data.data];
          if (response.data.index < response.data.count - 1) {
            this.getFacilityReport(parseInt(response.data.index) + 1);
          }
        });
    },
  },

  created() {
    this.$document.title = "Homepage";
    Api(true)
      .get("ticket/systems")
      .then((r) => {
        this.databases_data = r.data;

        this.getFacilityReport(0);
      });
  },
};
</script>

<style>
.bg-nav {
  --tw-bg-opacity: 1;
  background-color: #06325fd9;
}
.is-small-left-heading {
  position: absolute;
  left: 2px;
  top: 2px;
  font-size: 8px;
}
.left-sided-report {
  position: absolute;
  right: 2px;
}
</style>
